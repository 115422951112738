import { navigate } from '@reach/router'
import { graphql, Link } from 'gatsby'
import type { FC } from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { Divider } from '../components/Overrides'
import { SEO } from '../components/Seo'
import { normalizeContentList } from '../helpers/normalizeContentList'
import { useKeyPress } from '../hooks/useKeyPress'

import type { ContentListProps } from './types'

const Posts: FC<ContentListProps> = ({ data, pageContext }) => {
  useKeyPress('arrowleft', () => {
    if (pageContext.previousPageLink) {
      navigate(pageContext.previousPageLink)
    }
  })

  useKeyPress('arrowright', () => {
    if (pageContext.nextPageLink) {
      navigate(pageContext.nextPageLink)
    }
  })

  const posts = normalizeContentList(data)

  return (
    <Layout>
      <SEO
        keywords={['posts']}
        pathname={pageContext.currentPageLink}
        title={`All Posts | Page ${pageContext.currentPage}`}
      />

      <PostBox>
        {posts.map((post) => {
          return (
            <div key={post.slug}>
              <h2>
                <Link to={post.slug}>{post.title}</Link>
              </h2>
              <small>{post.datePublished}</small>
              <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </div>
          )
        })}
      </PostBox>

      {(pageContext.previousPageLink || pageContext.nextPageLink) && (
        <>
          <Divider />
          <PaginationBox>
            <div>
              {pageContext.previousPageLink && (
                <Link to={pageContext.previousPageLink} rel="prev">
                  Page {pageContext.currentPage - 1}
                </Link>
              )}
            </div>
            <div>
              {pageContext.nextPageLink && (
                <Link to={pageContext.nextPageLink} rel="next">
                  Page {pageContext.currentPage + 1}
                </Link>
              )}
            </div>
          </PaginationBox>
        </>
      )}
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default Posts

const PaginationBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const PostBox = styled.section`
  margin-bottom: 40px;
`

export const postsQuery = graphql`
  query postsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        frontmatter: { published: { eq: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
