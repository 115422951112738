interface Note {
  datePublished: string
  excerpt: string
  title: string
  slug: string
}

export interface GQLContentList {
  allMarkdownRemark: {
    edges: Array<{
      node: {
        excerpt: string
        fields: {
          slug: string
        }
        frontmatter: {
          date: string
          title: string
        }
      }
    }>
  }
}

export const normalizeContentList = (
  gqlContentList: GQLContentList,
): Note[] => {
  return gqlContentList.allMarkdownRemark.edges.map((markdownRemark) => ({
    datePublished: markdownRemark.node.frontmatter.date,
    excerpt: markdownRemark.node.excerpt,
    slug: markdownRemark.node.fields.slug,
    title: markdownRemark.node.frontmatter.title,
  }))
}
